<template>
  <div class="profile-container">
    <Breadcrumb :items="breadCrumbs" />
    <v-container fluid class="eugenio-section">
      <router-view></router-view>
    </v-container>
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

export default {
  name: "Eugenio",
  components: { Breadcrumb },
  computed: {
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [{ href: "/spesa-online", text: "Homepage" }];
      let breadcrumb = "";
      for (let i = 1; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            exact: false,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          });
        }
      }
      return breadCrumbs;
    }
  }
};
</script>
